.el-date-editor.el-input[data-v-c0732550], .el-date-editor.el-input__inner[data-v-c0732550] {
  width: 180px !important;
}
.save_box[data-v-c0732550] {
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.total_style[data-v-c0732550] {
  font-size: 14px;
  cursor: pointer;
}
.total_style span[data-v-c0732550] {
  color: var(--themeColor, #17a2b8);
  margin-right: 3px;
}
.total_style .icon-gantanhao1[data-v-c0732550] {
  font-size: 14px;
}
.content[data-v-c0732550] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
}
.content span[data-v-c0732550] {
  line-height: 28px;
  font-size: 13px;
}
.content tr[data-v-c0732550] {
  padding: 0;
  margin: 0;
}
.content td[data-v-c0732550] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.content_head[data-v-c0732550] {
  margin-top: 10px;
  border-bottom: none;
}
.content_body[data-v-c0732550] {
  border-top: none;
}
.left_k[data-v-c0732550] {
  text-indent: 2em;
}
.left_b[data-v-c0732550] {
  text-indent: 4em;
}
.left_x[data-v-c0732550] {
  text-indent: 5em;
}
.left_d[data-v-c0732550] {
  text-indent: 8em;
}
.top_title3[data-v-c0732550] {
  width: 100%;
  margin: 0 auto;
}
.top_title3 h4[data-v-c0732550] {
  font-size: 15px;
  color: #333;
  font-weight: 600;
  text-align: center;
}
.top_title3 h5[data-v-c0732550] {
  font-size: 13px;
  color: #333;
  font-weight: normal;
  text-align: center;
  margin-top: 3px;
}
.top_title3 .bottom_font[data-v-c0732550] {
  width: 100%;
  margin: 0 auto;
  margin-top: 0;
}
.top_title3 .bottom_font h6[data-v-c0732550] {
  float: left;
  width: 33.33%;
  font-size: 13px;
  color: #333;
  font-weight: normal;
}
.top_title3 .bottom_font h6[data-v-c0732550]:nth-child(1) {
  padding-top: 10px;
}
.top_title3 .bottom_font h6[data-v-c0732550]:nth-child(2) {
  padding-top: 10px;
}
.center[data-v-c0732550] {
  text-align: center;
}
.left[data-v-c0732550] {
  text-align: left;
}
.right[data-v-c0732550] {
  text-align: right;
}
.weight[data-v-c0732550] {
  font-weight: 700;
}
.row_box[data-v-c0732550] {
  border-bottom: 1px solid #eee;
}
.big_box4[data-v-c0732550] {
  width: 100%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly[data-v-c0732550] {
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
i[data-v-c0732550] {
  font-size: 13px;
  margin-right: 3px;
}