.big_box4[data-v-0b17b210] {
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
}
.big_box4 .add[data-v-0b17b210] {
  width: 1.4em;
  height: 1.4em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  left: 12px;
  top: 175px;
  font-size: 16px;
  color: #f15a24;
  cursor: pointer;
}
.big_box4 .add .svg-icon[data-v-0b17b210] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.content[data-v-0b17b210] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
}
.content span[data-v-0b17b210] {
  line-height: 28px;
  font-size: 13px;
}
.content tr[data-v-0b17b210] {
  padding: 0;
  margin: 0;
}
.content td[data-v-0b17b210] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.content input[data-v-0b17b210] {
  width: 100%;
  line-height: 28px;
  padding: 0 3px;
  font-size: 13px;
  text-align: right;
}
.right[data-v-0b17b210] {
  text-align: right;
}
.center[data-v-0b17b210] {
  text-align: center;
}
.save_box[data-v-0b17b210] {
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}